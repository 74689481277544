import React, { useEffect } from 'react';
import Logo from '@/assets/img/logo.png';

const Header = () => {
  const scroll = (id) => {
    const el = document.getElementById(id);
    if (el) {
      const currentScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop || 0;
      const targetScrollTop =
        el.getBoundingClientRect().top + currentScrollTop - 100;

      window.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth', // Tùy chọn để có hiệu ứng cuộn mượt
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="content">
            <div className="logo">
              <img src={Logo} alt="HTI Scientific" />
            </div>
            <div className="menu-list">
              <a className="menu-item" onClick={() => scroll('sectionAbout')}>
                GIỚI THIỆU
              </a>
              <a className="menu-item" onClick={() => scroll('sectionProduct')}>
                SẢN PHẨM
              </a>
              <a className="menu-item" onClick={() => scroll('sectionBanner')}>
                KHUYẾN MÃI
              </a>
              <a className="menu-item" onClick={() => scroll('sectionContact')}>
                LIÊN HỆ
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default React.memo(Header);
