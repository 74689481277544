import LoadableComponent from '@components/Loadable';
import { PAGE_URL } from '@/config/AppConst';

// When user is logged, user go to page has restrict = true app auto redirect to Home Page
// Using restrict = false. Keep this page.
export const PublicRoutes = [
  {
    path: '/',
    title: 'perkinelmervietnam',
    component: LoadableComponent(() => import('@/screens/Homepage')),
  },
  {
    path: PAGE_URL.HOME,
    title: 'perkinelmervietnam',
    component: LoadableComponent(() => import('@/screens/Homepage')),
  },
];

export default [...PublicRoutes];
