import React, { useEffect } from 'react';
import Logo from '@/assets/img/logo.png';
import LogoPerkin from '@/assets/img/logo-perkin.png';
import {
  EnvironmentOutlined,
  FacebookOutlined,
  GlobalOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
  PhoneOutlined,
  TwitterOutlined,
} from '@ant-design/icons';

const Footer = () => {
  useEffect(() => {}, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="logo-wrap">
                <img src={Logo} alt="HTI Scientific" />
                <img src={LogoPerkin} alt="Perkin" />
              </div>
              <div className="sub">
                Nhà phân phối ủy quyền chính thức của PerkinElmer tại Việt Nam.
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-2">
              <div className="name">Sản phẩm</div>
              <div className="list">
                <div className="item">Quang phổ nguyên tử</div>
                <div className="item">Sắc ký</div>
                <div className="item">Sắc ký khối phổ</div>
                <div className="item">Quang phổ phân tử</div>
                <div className="item">Phân tích nhiệt</div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-2">
              <div className="name">Điều khoản</div>
              <div className="list">
                <div className="item">Điều khoản dịch vụ</div>
                <div className="item">Chính sách bảo mật</div>
                <div className="item">Tin cậy và an toàn</div>
                <div className="item">Cam kết</div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="name">LIÊN HỆ CHÚNG TÔI</div>
              <div className="list">
                <div className="item">
                  <div className="mb-2">
                    <span className="mr-3">
                      <EnvironmentOutlined style={{ color: '#0E76BC' }} />
                    </span>
                    <b>HN:</b> 21 Lê Đức Thọ, Mỹ Đình 2, Nam Từ Liêm, Hà Nội
                  </div>
                  <div style={{ padding: '0 0 0 33px' }}>
                    <b>HCM:</b> 203A Võ Thị Sáu, P. Võ Thị Sáu, Quận 3, HCM
                  </div>
                </div>
                <div className="item">
                  <span className="mr-3">
                    <PhoneOutlined style={{ color: '#0E76BC' }} />
                  </span>
                  <b>Hotline:</b> <a href="tel:0981230055">098.123.0055</a>
                </div>
                <div className="item">
                  <span className="mr-3">
                    <MailOutlined style={{ color: '#0E76BC' }} />
                  </span>
                  <b>Email:</b>{' '}
                  <a href="mailto:sales@htigroup.vn">sales@htigroup.vn</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="left">© 2024 HTI Scientific All Right Reserved</div>
          <div className="right">
            <div className="language">
              <GlobalOutlined className="mr-1" /> Tiếng Việt (VNĐ)
            </div>
            <div className="list">
              <div className="item">
                <FacebookOutlined />
              </div>
              <div className="item">
                <TwitterOutlined />
              </div>
              <div className="item">
                <InstagramOutlined />
              </div>
              <div className="item">
                <LinkedinOutlined />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
